import { useEffect, useRef, useState } from 'react';

import {
  DefaultLanguageOption,
  type SupportedLanguages,
} from '../../i18n/language-options';
import { apiService } from '../../services/api-service';
import { type ISubtitlesManager } from '../VoiceOver/LocalSubtitlesManager';
import {
  configureExpireDurationMs,
  configureLineLength,
} from './MetricsCreator';
import { SubtitlesPrinterV2 } from './SubtitlesPrinterV2';

export function SubtitlesAreaV2(props: {
  className: string;
  currentLanguage: { label: string; value: string };
  subman: ISubtitlesManager;
  isV2?: boolean;
}) {
  // We use the types just to make the utilities easier to write / maintain, but
  // the incoming value is unchecked.
  const currentLanguageCode = props.currentLanguage.value as SupportedLanguages;

  const ref = useRef<null | HTMLDivElement>(null);
  const [printer] = useState(
    () =>
      new SubtitlesPrinterV2(
        ref,
        configureLineLength(currentLanguageCode, props.isV2),
        configureExpireDurationMs(currentLanguageCode),
        1,
        ['text-center', 'text-white', 'text-base', 'lg:text-xl', 'font-bold'],
        {
          fontFamily: 'PT Sans Caption',
          textShadow: '1.5px 1.5px 1.5px black',
        }
      )
  );

  useEffect(() => {
    return props.subman.on('script-now', async (line) => {
      if (DefaultLanguageOption.value === currentLanguageCode) {
        printer.accept(line, currentLanguageCode);
      } else {
        const translated = await apiService.translation.translate({
          text: line,
          sourceLanguageCode: DefaultLanguageOption.value,
          targetLanguageCode: currentLanguageCode,
        });
        printer.accept(
          translated.data.text,
          translated.data.targetLanguageCode
        );
      }
    });
  }, [printer, currentLanguageCode, props.subman]);

  const previousLanguageRef = useRef(props.currentLanguage);
  useEffect(() => {
    if (currentLanguageCode !== previousLanguageRef.current.value) {
      printer.clear();
      printer.updateMaxLineLength(
        configureLineLength(currentLanguageCode, props.isV2)
      );
      printer.accept(
        '[ updating translation. . . ]',
        DefaultLanguageOption.value
      );
      previousLanguageRef.current = props.currentLanguage;
    }
  }, [currentLanguageCode, printer, props.currentLanguage, props.isV2]);

  useEffect(() => {
    return () => {
      printer.destroy();
    };
  }, [printer]);

  return (
    <div className={`${props.className}`} ref={ref}>
      {/* Subtitles will be printed here */}
    </div>
  );
}
