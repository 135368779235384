import { Emitter } from '../../utils/emitter';

export interface ISubtitlesManager {
  on: Emitter<SubtitleManagerEvents>['on'];
  /**
   * - `local`: this is for the current user only
   * - `broadcast`: this is for all users in the venue
   *
   * It is expected that `local` is from a local-only voiceover, such as the H2H
   * block. `broadcast` is likely only ever specified on the controller so that
   * all users receive the subtitles.
   */
  notify(
    kind: 'local' | 'broadcast',
    script: string,
    info: { trackStarted: Promise<void> }
  ): Promise<void>;
}

type SubtitleManagerEvents = {
  'script-now': (script: string) => void;
};

export class LocalSubtitlesManager implements ISubtitlesManager {
  private emitter = new Emitter<SubtitleManagerEvents>();

  on = this.emitter.on.bind(this.emitter);

  async notify(
    kind: 'local' | 'broadcast',
    script: string,
    info: { trackStarted: Promise<void> }
  ) {
    await info.trackStarted;

    if (kind === 'broadcast') {
      throw new Error('Cannot Broadcast from a LocalSubtitlesManager');
    } else {
      this.emitter.emit('script-now', script);
    }
  }

  destroy() {
    this.emitter.clear();
  }
}
