import { type CommonMedia } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { fromMediaDTO } from '../../../utils/api-dto';
import { xDomainifyUrl } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { UnplayableAudioImpl } from '../../../utils/unplayable';
import { type TrackId, TrackInitConfigBuilder } from '../../VideoMixer';
import { makeLocalAudioOnlyVideoMixer } from '../../VoiceOver/makeLocalAudioOnlyVideoMixer';

const fadeDurationMs = 300;

export class MusicControl {
  readonly localVM = makeLocalAudioOnlyVideoMixer('pool');
  readonly vm = this.localVM[0];

  private currentMedia: Nullable<Media>;
  private currentTrackId: Nullable<TrackId>;

  play(): void {
    this.vm.play();
  }

  async switchTrack(incoming: Nullable<Media | CommonMedia>) {
    const media = fromMediaDTO(incoming);
    const url = MediaUtils.PickMediaUrl(media);
    if (this.currentMedia && (!url || !media)) {
      this.fadeOutCurrentTrack();
      return;
    }

    if (!url || !media) {
      return;
    }

    if (this.currentMedia?.hash === media.hash) {
      return;
    }

    const unplayable = UnplayableAudioImpl.FromWebAudioPool(xDomainifyUrl(url));
    await unplayable.intoPlayable();
    const durationMs = unplayable.media?.duration * 1000;
    this.fadeOutCurrentTrack();

    const trackInitConfig = new TrackInitConfigBuilder()
      .setTimelineTimeStartMs(this.vm.playheadMs)
      .setDurationMs(durationMs)
      .addAudioGainEnvelope(
        { ms: 0, value: 0 },
        { ms: fadeDurationMs, value: 0.25 },
        'linear'
      )
      .setLoop(true)
      .build();

    this.currentMedia = media;
    this.currentTrackId = this.vm.pushTrack(unplayable.media, trackInitConfig);
  }

  private fadeOutCurrentTrack() {
    if (this.currentTrackId) {
      const trackElapsedTimeMs = this.vm.getTrackElapsedTimeMs(
        this.currentTrackId
      );
      if (trackElapsedTimeMs !== null) {
        this.vm.patchTrack(this.currentTrackId, {
          audioEffects: {
            gain: {
              kind: 'gain',
              trackLocalEnvelopes: [
                {
                  start: {
                    ms: trackElapsedTimeMs,
                    value: 0.25,
                  },
                  end: {
                    ms: trackElapsedTimeMs + fadeDurationMs,
                    value: 0,
                  },
                  curve: 'linear',
                },
              ],
            },
          },
          timelineTimeEndMs: this.vm.playheadMs + fadeDurationMs,
        });
      }
    }
  }

  pause(): void {
    this.vm.pause();
  }

  destroy(): void {
    this.vm.destroy();
  }
}
