import { useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';
import { proxy } from 'valtio';

import {
  EnumsDialogueGenerationType,
  EnumsDialogueOfflineRenderStatus,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';
import type { SlideBlock, SlideBlockAnimationKey } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { fromMediaDTO } from '../../../utils/api-dto';
import { assertExhaustive, sleep } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { markSnapshottable, useSnapshot } from '../../../utils/valtio';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useLogoBrandColor } from '../../VenueOrgLogoAverageColor/useLogoBrandColor';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../VoiceOver/LocalLocalizedVoiceOvers';
import { usePersonality } from '../../VoiceOver/usePersonalities';
import {
  BlockAnimation,
  BlockAnimator,
  BlockAnimatorProvider,
  useBlockAnimator,
} from '../apis/BlockAnimationControl';
import { useOrgMasqueradeFallback } from '../apis/OrgMasqueradeFallback';
import { CommonButton } from '../design/Button';
import { EditableTextDisplay } from '../design/EditableDisplay';
import { ScrollableContent } from '../design/ScrollableContent';
import { TrainingLogo } from '../design/TrainingLogo';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../types';

const Animate = BlockAnimation<SlideBlockAnimationKey>;

function newAvatarVideoElement(entry: ResolvedDialogueEntry) {
  const avatar = entry.offlineRender?.avatar;
  const avatarVideoUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media));
  if (!avatarVideoUrl) return null;

  const avatarPosterUrl = MediaUtils.PickMediaUrl(fromMediaDTO(avatar?.media), {
    videoThumbnail: 'first',
  });

  const video = document.createElement('video');
  video.preload = 'auto';
  video.muted = true;
  video.playsInline = true;
  video.className = 'w-full h-full object-cover';
  video.src = avatarVideoUrl;
  if (avatarPosterUrl) {
    video.poster = avatarPosterUrl;
  }
  return video;
}

type ResolvedDialogueEntry = ModelsDialogueEntry & {
  resolvedScript: string;
};

async function preloadResolvedDialogueEntry(
  entry: ResolvedDialogueEntry,
  logger: Logger,
  videoCache: Map<string, HTMLVideoElement>
) {
  switch (entry.generationType) {
    case EnumsDialogueGenerationType.DialogueGenerationTypeClient:
      await lvoLocalCacheWarm({
        script: entry.resolvedScript,
        personalityId: entry.personalityId,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      });
      return;
    case EnumsDialogueGenerationType.DialogueGenerationTypeOffline:
      const tasks: Promise<void>[] = [];
      const video = newAvatarVideoElement(entry);
      if (video) {
        videoCache.set(entry.id, video);
        tasks.push(
          new Promise<void>(async (resolve) => {
            try {
              await video.play();
              video.pause();
              video.currentTime = 0;
            } catch (e) {
              logger?.error('Failed to preload avatar video', { e });
            } finally {
              resolve();
            }
          })
        );
      }

      const voiceOver = entry.offlineRender?.voiceOver;
      const voiceOverUrl = MediaUtils.PickMediaUrl(
        fromMediaDTO(voiceOver?.media)
      );
      if (voiceOverUrl) {
        tasks.push(fetch(voiceOverUrl).then(() => void 0));
      }

      await Promise.all(tasks);
      return;
    default:
      assertExhaustive(entry.generationType);
      return;
  }
}

export class SlideBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<{
      status: 'init' | 'present' | 'presented';
      playingDialogueEntryIndex: number;
      playingDialogueEntry: Nullable<ResolvedDialogueEntry>;
    }>({
      status: 'init',
      playingDialogueEntryIndex: -1,
      playingDialogueEntry: null,
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private resolvedDialogueEntries: Nullable<ResolvedDialogueEntry[]>;
  blockAnimator: BlockAnimator<SlideBlockAnimationKey>;
  public videoCache: Map<string, HTMLVideoElement>;
  // note: this is bounded by the size of the audio pool.
  private preloadCount = 3;

  constructor(private block: SlideBlock, private deps: BlockDependencies) {
    this.logger = deps.getLogger('slide-block');
    this.blockAnimator = new BlockAnimator(block.fields.animations ?? {});
    this.videoCache = new Map();
  }

  get state() {
    return this._state;
  }

  async preload() {
    this.resolvedDialogueEntries = await this.resolveDialogue();
    if (
      !this.resolvedDialogueEntries ||
      this.resolvedDialogueEntries.length === 0
    )
      return;

    const preloads = this.resolvedDialogueEntries.slice(0, this.preloadCount);
    await Promise.all(
      preloads.map((entry) =>
        preloadResolvedDialogueEntry(entry, this.logger, this.videoCache)
      )
    );
  }

  private async resolveDialogue(): Promise<ResolvedDialogueEntry[]> {
    const dialogue = this.block.fields.dialogue;
    const entries = dialogue?.entries ?? [];
    let resolvedEntries = await Promise.all(
      entries.map(async (entry) => {
        let resolvedScript = entry.script;
        if (
          entry.generationType ===
          EnumsDialogueGenerationType.DialogueGenerationTypeClient
        ) {
          const resolved = await this.deps.commonVariableRegistry.render(
            resolvedScript
          );
          resolvedScript = resolved.script;
        }
        return {
          ...entry,
          resolvedScript,
        };
      })
    );
    resolvedEntries = resolvedEntries.filter(
      (entry) => entry.script.trim().length > 0
    );
    return resolvedEntries;
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
    this.blockAnimator.on();
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    this._state.status = 'present';

    if (
      !this.resolvedDialogueEntries ||
      this.resolvedDialogueEntries.length === 0
    ) {
      await sleep(3000);
    } else {
      try {
        const info = playDialogue(
          this.resolvedDialogueEntries,
          this.preloadCount,
          this.logger,
          this.videoCache,
          (entry, index) => {
            this._state.playingDialogueEntry = entry;
            this._state.playingDialogueEntryIndex = index;
          }
        );
        await info.started;
        this.blockAnimator.triggerAnimations('start');
        await info.ended;
        this.blockAnimator.triggerAnimations('end');
      } catch (e) {
        await sleep(3000);
      }
    }

    this._state.status = 'presented';

    if (this.block.fields.hideButton) {
      this.end();
    }
  }

  async replay() {
    this.blockAnimator.reset(this.block.fields.animations ?? {});
    this.present();
  }

  async end() {
    this.videoCache.clear();
    this.blockAnimator.destroy();
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidCommit();
    await this.delegate?.blockDidEnd();
  }
}

// TODO(falcon): consider sharing this.
function playDialogue(
  entries: ResolvedDialogueEntry[],
  preloadCount: number,
  logger: Logger,
  videoCache: Map<string, HTMLVideoElement>,
  onEntryPlaying?: (entry: ResolvedDialogueEntry, index: number) => void
): {
  started: Promise<void>;
  ended: Promise<void>;
} {
  const started = Promise.withResolvers<void>();
  const ended = Promise.withResolvers<void>();
  const result = {
    started: started.promise,
    ended: ended.promise,
  };

  (async () => {
    if (entries.length === 0) {
      started.resolve();
      ended.resolve();
      return;
    }

    let hasResolvedStarted = false;
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const nextEntry = entries[i + preloadCount];
      if (nextEntry) {
        preloadResolvedDialogueEntry(nextEntry, logger, videoCache);
      }

      try {
        const player = new LVOLocalPlayer({
          script: entry.resolvedScript,
          personalityId: entry.personalityId,
          cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
          policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
          renderedMedia: fromMediaDTO(entry.offlineRender?.voiceOver?.media),
        });
        const info = await player.playFromPool();
        await info?.trackStarted;
        if (onEntryPlaying) {
          onEntryPlaying(entry, i);
        }
        if (!hasResolvedStarted) {
          // it's possible that the first entry failed to play for some reason...
          started.resolve();
          hasResolvedStarted = true;
        }
        await info?.trackEnded;
      } catch (e) {
        // TODO(falcon): log...
      }
      // little bit of jitter to make it feel more natural
      await sleep(Math.random() * (125 - 25) + 25);
    }
    if (!hasResolvedStarted) {
      // if we haven't resolved started, then we never started. mark it resolved so others continue.
      started.resolve();
    }
    ended.resolve();
  })();

  return result;
}

function Logo() {
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  return <TrainingLogo ref={animator.ref('logo')} />;
}

function BigTitle(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />
      <Animate
        name='heading'
        className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}

function Classic(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col sm:flex-row gap-5'>
      <div className='w-full sm:w-1/2 flex flex-col gap-5'>
        <Logo />
        <Animate
          name='heading'
          className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
        >
          <EditableTextDisplay value={props.block.fields.heading ?? ''} />
        </Animate>
        <Animate name='subtitle' className='text-base sm:text-xl lg:text-2xl'>
          <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
        </Animate>
      </div>
      <Animate name='media' className='w-full sm:w-1/2'>
        <img
          src={props.block.fields.media?.media?.url}
          alt={''}
          className='w-full h-full object-contain'
        />
      </Animate>
    </div>
  );
}

function BulletPoints(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />

      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <ol
        className={`
          list-decimal list-inside
          text-base sm:text-xl lg:text-2xl
          space-y-2 sm:space-y-4
        `}
      >
        {props.block.fields.bulletPoints.map((point, index) => (
          <BlockAnimation<SlideBlockAnimationKey, 'li'>
            key={index}
            name={`bullet-${index + 1}`}
            as='li'
          >
            {point}
          </BlockAnimation>
        ))}
      </ol>
    </div>
  );
}

function TitleAndText(props: { block: SlideBlock }) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />
      <Animate
        name='heading'
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableTextDisplay value={props.block.fields.heading ?? ''} />
      </Animate>
      <Animate name='subtitle' className='text-base sm:text-xl lg:text-xl'>
        <EditableTextDisplay value={props.block.fields.subtitle ?? ''} />
      </Animate>
    </div>
  );
}

function Content(props: { block: SlideBlock }) {
  switch (props.block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutBigTitle:
      return <BigTitle block={props.block} />;
    case EnumsSlideLayout.SlideLayoutClassic:
      return <Classic block={props.block} />;
    case EnumsSlideLayout.SlideLayoutBulletPoints:
      return <BulletPoints block={props.block} />;
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      return <TitleAndText block={props.block} />;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return null;
    default:
      return null;
  }
}

function AvatarPlaceholder(props: {
  personalityId: string;
  className?: string;
  showPlaceholder?: boolean;
}) {
  // TODO(guoqiang): preload avatar when playground init.
  const personality = usePersonality(props.personalityId);
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );

  if (!mediaUrl) return null;
  return (
    <div className={`relative ${props.className}`}>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      {props.showPlaceholder && (
        <div className='absolute inset-0 rounded-full flex justify-center items-center'>
          <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
        </div>
      )}
    </div>
  );
}

function FullHostAvatar(props: {
  ctrl: SlideBlockControlAPI;
  className?: string;
}) {
  const status = useSnapshot(props.ctrl.state).status;
  const prevStatus = usePrevious(status);
  const playingEntry = useSnapshot(props.ctrl.state)
    .playingDialogueEntry as Nullable<ResolvedDialogueEntry>;
  const ref = useRef<HTMLDivElement>(null);

  const reply = prevStatus === 'presented' && status === 'present';
  useEffect(() => {
    if (!playingEntry?.id) return;

    const video = props.ctrl.videoCache.get(playingEntry.id);
    if (video) {
      video.currentTime = 0;
      video.autoplay = true;
      video.play();
      if (ref.current?.firstChild) {
        ref.current?.removeChild(ref.current.firstChild);
      }
      ref.current?.appendChild(video);
    }
  }, [playingEntry?.id, reply, props.ctrl.videoCache]);

  if (!playingEntry) return null;

  const avatar = playingEntry?.offlineRender?.avatar;
  if (!avatar) {
    return (
      <AvatarPlaceholder
        personalityId={playingEntry.personalityId}
        className={props.className}
        showPlaceholder={
          playingEntry.offlineRender?.status ===
          EnumsDialogueOfflineRenderStatus.DialogueOfflineRenderStatusProcessing
        }
      />
    );
  }
  return <div className={props.className} ref={ref}></div>;
}

function Background(props: { block: SlideBlock; ctrl: SlideBlockControlAPI }) {
  const { data: color } = useLogoBrandColor(useOrgMasqueradeFallback());

  const brandColor = useMemo(() => {
    if (!color) return '#FBB707';
    return color.cssRGBA(1);
  }, [color]);

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost)
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
      </div>
    );
  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
        <Animate
          name='overlay'
          className={`absolute inset-0 bg-opacity-60 bg-black`}
        />
      </div>
    );
  }
  if (
    props.block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionBrand
  ) {
    return (
      <div
        className='fixed inset-0 opacity-20'
        style={{ backgroundColor: brandColor }}
      />
    );
  }
  return null;
}

export function SlideBlockPlaygroundInternal(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  const status = useSnapshot(props.ctrl.state).status;

  useEffectOnce(() => {
    props.ctrl.present();
  });

  const isFullHostLayout =
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;
  const showContent = !isFullHostLayout;

  return (
    <>
      <Background block={props.block} ctrl={props.ctrl} />

      <div
        className={
          'relative w-full h-full p-5 flex flex-col justify-between items-center text-white'
        }
      >
        {isFullHostLayout && (
          <div className='top-[70%] absolute z-5 flex-none w-full min-w-70 max-w-125 flex items-center gap-2.5 px-5'>
            <div className='flex-1 h-12 lg:h-14 flex justify-center'>
              {props.subtitlesArea}
            </div>
          </div>
        )}

        {showContent && (
          <div className='w-full min-h-0 flex-1 p-5 sm:p-8 lg:p-10'>
            <ScrollableContent>
              <Content block={props.block} />
            </ScrollableContent>
          </div>
        )}

        {!props.block.fields.hideButton && (
          <div
            className={`${
              isFullHostLayout ? 'mt-auto' : ''
            } w-full flex items-center justify-center gap-2`}
          >
            <CommonButton
              variant='gray'
              onClick={() => {
                props.ctrl.replay();
              }}
              disabled={status !== 'presented'}
              styles={{ size: 'h-full' }}
              style={{
                aspectRatio: '1/1',
              }}
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
            </CommonButton>
            <CommonButton
              variant='brand'
              onClick={() => {
                props.ctrl.end();
              }}
              disabled={status !== 'presented'}
              className='flex-none'
            >
              <EditableTextDisplay
                value={props.block.fields.buttonText ?? ''}
              />
            </CommonButton>
          </div>
        )}
      </div>
    </>
  );
}

export function SlideBlockPlayground(props: {
  block: SlideBlock;
  ctrl: SlideBlockControlAPI;
  subtitlesArea: JSX.Element;
}) {
  return (
    <BlockAnimatorProvider value={props.ctrl.blockAnimator}>
      <SlideBlockPlaygroundInternal {...props} />
    </BlockAnimatorProvider>
  );
}
