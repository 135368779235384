import {
  type ButtonHTMLAttributes,
  forwardRef,
  type PropsWithChildren,
  type Ref,
  useMemo,
} from 'react';

import { useLogoBrandColor } from '../../VenueOrgLogoAverageColor/useLogoBrandColor';
import { useOrgMasqueradeFallback } from '../apis/OrgMasqueradeFallback';

export type RawButtonStyles = Partial<{
  size: string;
  spacing: string;
  layout: string;
  text: string;
  disabled: string;

  primaryColor: string;
  secondaryColor: string;

  // swaps the primary and secondary colors
  invert?: boolean;
  // uses the secondary color as the border color
  bordered?: boolean;
}>;

export const RawButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<
    ButtonHTMLAttributes<HTMLButtonElement> & {
      styles?: RawButtonStyles;
    }
  >
>((props, ref) => {
  const { styles, ...rest } = props;
  const primaryColor = styles?.primaryColor;
  const secondaryColor =
    styles?.secondaryColor ?? primaryColor
      ? `hsl(from ${primaryColor} h s calc(l * 0.5) / alpha)`
      : undefined;

  const [foregroundColor, backgroundColor] = styles?.invert
    ? [secondaryColor, primaryColor]
    : [primaryColor, secondaryColor];

  return (
    <button
      ref={ref}
      {...rest}
      type='button'
      className={`
        ${styles?.size ?? 'w-full min-h-11.5 max-w-75'}
        ${styles?.text ?? 'text-white text-base font-bold'}
        ${styles?.layout ?? 'flex items-center justify-center'}
        ${styles?.spacing ?? 'pb-[6px]'}
        ${
          styles?.bordered
            ? 'border border-[color:var(--secondary-color)]'
            : 'border-0'
        }
        relative inline-block group
        bg-[color:var(--secondary-color)]
        rounded-xl bg-clip-padding
        ${
          !props.disabled
            ? 'active:pt-[6px] active:pb-0 active:bg-transparent'
            : ''
        }
        appearance-none outline-none focus:outline-none
        select-none tap-highlight-transparent
        ${
          styles?.disabled ??
          'disabled:cursor-auto disabled:pointer-events-off disabled:opacity-50'
        }
        ${props.className || ''}
      `}
      style={
        {
          ...props.style,
          '--primary-color': foregroundColor,
          '--secondary-color': backgroundColor,
          transform: 'translateZ(0)',
        } as React.CSSProperties
      }
    >
      {props.children}
      <div
        className={`
          z-[-1] absolute inset-0 bottom-[6px]
          rounded-xl bg-[color:var(--primary-color)]
          filter transition-[filter]
          group-hover:brightness-110
          ${
            !props.disabled
              ? 'group-active:top-[6px] group-active:bottom-0 group-active:!brightness-100'
              : ''
          }
       `}
      />
    </button>
  );
});

export type ButtonVariants = Record<string, RawButtonStyles>;
export type ButtonProps<T extends ButtonVariants> = {
  variant: keyof T;
  variants: T;
  children?: React.ReactNode;
  ref?: Ref<HTMLButtonElement>;
  styles?: RawButtonStyles;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button<T extends ButtonVariants = ButtonVariants>(
  props: ButtonProps<T>
) {
  const { variant, variants, styles, ...rest } = props;
  return (
    <RawButton
      ref={props.ref}
      styles={{ ...styles, ...variants[variant] }}
      {...rest}
    />
  );
}

export type CommonButtonVariant = 'correct' | 'incorrect' | 'gray' | 'brand';
const commonVariants: Record<CommonButtonVariant, RawButtonStyles> = {
  correct: {
    primaryColor: '#39D966',
  },
  incorrect: {
    primaryColor: '#FF0935',
  },
  gray: {
    primaryColor: '#4B4B4B',
  },
  brand: {
    primaryColor: '#FBB707',
  },
} as const;

export function CommonButton(
  props: Omit<ButtonProps<typeof commonVariants>, 'variants'>
) {
  const { data: color } = useLogoBrandColor(useOrgMasqueradeFallback());

  const variants = useMemo(
    () => ({
      ...commonVariants,
      brand: color ? { primaryColor: color.cssRGBA(1) } : commonVariants.brand,
    }),
    [color]
  );

  return <Button<typeof commonVariants> {...props} variants={variants} />;
}

export function GamePlayButton(
  props: Omit<ButtonProps<typeof commonVariants>, 'variants'>
) {
  return <CommonButton {...props} styles={{ ...props.styles, invert: true }} />;
}
