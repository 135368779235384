import { XBoldIcon } from '../../icons/XIcon';

export function StatusBar(props: {
  progressPct: number;
  onClose?: () => void;
}) {
  const { progressPct, onClose } = props;

  return (
    <div className='w-full h-4 flex items-center gap-2.5'>
      <button type='button' className='btn flex-none' onClick={onClose}>
        <XBoldIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      <div className='flex-1 h-full bg-light-gray rounded-lg'>
        <div
          className='h-full rounded-lg bg-green-001 transition-size'
          style={{ width: `${progressPct}%` }}
        />
      </div>
    </div>
  );
}
