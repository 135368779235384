import {
  Alignment,
  type EventCallback,
  EventType,
  Fit,
  Layout,
  useRive,
} from '@rive-app/react-canvas';
import { useEffect } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../../utils/assets';

type CorrectAnimProps = {
  onEnd: () => void;
};

function CorrectAnimation(props: CorrectAnimProps) {
  const { RiveComponent, rive } = useRive({
    src: getStaticAssetPath('anims/correct-animation.riv'),
    autoplay: true,
    layout: new Layout({ fit: Fit.Cover, alignment: Alignment.TopCenter }),
  });

  const onEnd = useLiveCallback(() => {
    props.onEnd();
  });

  useEffect(() => {
    const onStop: EventCallback = (_ev) => {
      onEnd();
    };

    rive?.on(EventType.Stop, onStop);

    return () => {
      rive?.off(EventType.Stop, onStop);
    };
  }, [onEnd, rive]);

  return <RiveComponent />;
}

export function CorrectAnimationWithLayout(props: CorrectAnimProps) {
  return (
    <div
      className='
        absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
        pointer-events-none
        w-full h-full
      '
    >
      <CorrectAnimation {...props} />
    </div>
  );
}
