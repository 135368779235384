import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import type { MultipleChoiceBlock, MultipleChoiceOption } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { sleep } from '../../../utils/common';
import { markSnapshottable, useSnapshot } from '../../../utils/valtio';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../VoiceOver/LocalLocalizedVoiceOvers';
import { getStingerPrompt } from '../apis/StingerControl';
import {
  CommonButton,
  type CommonButtonVariant,
  GamePlayButton,
} from '../design/Button';
import { CorrectAnimationWithLayout } from '../design/CorrectAnimation';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../types';

function getEvaluationPrompt(
  question: string,
  answer: string,
  evaluation: string
) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a script writer for an online platform called Luna Park. Your only job is to write a script that informs a user if they answered correctly or incorrectly. I will give you the expected answer, the user's submitted answer, and how we have evaluated it.

Your script should be short and creative.

When the evaluation is incorrect, use only the provided question and answer to restate what the user missed. Do not use any other information or knowledge for this script.  

When the evaluation is correct, your script can be short and does not need to restate the answer.
Output only the script, with nothing before or after.

<example>
Question: Why is it challenging to host for an unseen audience?
Correct Answer: You can't adjust based on immediate feedback
Evaluation: incorrect
Output: Incorrect. One challenge for a host is they cannot adjust based on immediate feedback.
</example>

Output only the script, with nothing before or after. 

Question: ${question}
Correct Answer: ${answer}
Evaluation: ${evaluation}
\`\`\`
`.trim();
}

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  questionVisibility: 'visible' | 'hidden';
  choicesVisibility: 'visible' | 'hidden';
  choicesEnabled: boolean;
  selection?: MultipleChoiceOption | null;
  result?: 'correct' | 'incorrect' | null;
};

export class MultipleChoiceBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      questionVisibility: 'hidden',
      choicesVisibility: 'hidden',
      choicesEnabled: false,
    })
  );

  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    correct: null,
    incorrect: null,
  };

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;

  constructor(
    private block: MultipleChoiceBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('multiple-choice-block');
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;

    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.question = await this.questionTTS();
    this.resolvedTTS.correct = await this.correctTTS();
    this.resolvedTTS.incorrect = await this.incorrectTTS();
    lvoLocalCacheWarm(this.resolvedTTS.correct);
    lvoLocalCacheWarm(this.resolvedTTS.incorrect);
    await lvoLocalCacheWarm(this.resolvedTTS.question);
    await lvoLocalCacheWarm(this.resolvedTTS.stinger);
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await sleep(3000);
    }
    this._state.choicesVisibility = 'visible';
    this._state.choicesEnabled = true;
    this._state.status = 'play';
  }

  async submitChoice(choice: MultipleChoiceOption) {
    this.deps.sfxControl.play('instructionHoverReadyButton');

    this._state.status = 'grade';
    this._state.choicesEnabled = false;
    this._state.selection = choice;

    await sleep(1000);

    this._state.result = choice.correct ? 'correct' : 'incorrect';
    this._state.status = 'inform';

    this.deps.sfxControl.play(choice.correct ? 'rapidCorrect' : 'rapidWrong');
    try {
      const player = new LVOLocalPlayer(
        choice.correct ? this.resolvedTTS.correct : this.resolvedTTS.incorrect
      );
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play result TTS', e);
    }
    this._state.status = 'complete';
  }

  async end() {
    await this.delegate?.blockDidCommit();
    await this.delegate?.blockDidEnd();
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.question,
        'Tap the Fact',
        'A multiple choice question.'
      )
    );
  }

  private async questionTTS() {
    return this.makeTTSRenderRequest(this.block.fields.question);
  }

  private async correctTTS() {
    const correctChoice = this.block.fields.answerChoices.find(
      (c) => c.correct
    );
    return this.makeTTSRenderRequest(
      getEvaluationPrompt(
        this.block.fields.question,
        correctChoice?.text ?? '',
        'correct'
      )
    );
  }

  private async incorrectTTS() {
    const correctChoice = this.block.fields.answerChoices.find(
      (c) => c.correct
    );
    return this.makeTTSRenderRequest(
      getEvaluationPrompt(
        this.block.fields.question,
        correctChoice?.text ?? '',
        'incorrect'
      )
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.block.fields.personalityId) return null;
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

export function MultipleChoiceBlockPlayground(props: {
  block: MultipleChoiceBlock;
  ctrl: MultipleChoiceBlockControlAPI;
}) {
  const { question, answerChoices } = props.block.fields;
  const {
    status,
    questionVisibility,
    choicesVisibility,
    choicesEnabled,
    selection,
    result,
  } = useSnapshot(props.ctrl.state);

  useEffectOnce(() => {
    props.ctrl.present();
  });

  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main className='w-full flex-1 min-h-0 px-10 flex flex-col items-center justify-center gap-5 sm:gap-10'>
        <div
          className={`
            text-white text-center break-words
            text-base sm:text-xl lg:text-2xl
            transition-opacity duration-500 ${
              questionVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }
         `}
        >
          {question}
        </div>

        <div
          className={`w-full max-w-150 flex flex-col items-center gap-2 transition-opacity duration-500 ${
            choicesVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {answerChoices.map((choice, idx) => {
            const mySelection = selection?.text === choice.text;
            let variant: CommonButtonVariant = 'gray';
            if (result) {
              if (choice.correct) {
                variant = 'correct';
              } else if (mySelection) {
                variant = 'incorrect';
              }
            } else if (mySelection) {
              variant = 'brand';
            }
            const hasSelection = !!selection;

            return (
              <GamePlayButton
                key={idx}
                variant={variant}
                styles={{
                  text: 'text-sm font-bold text-white',
                  size: 'w-full min-h-11.5',
                  spacing: 'px-4 py-4',
                  bordered: result === 'incorrect' && choice.correct,
                }}
                className={hasSelection ? 'disabled:opacity-100' : ''}
                onClick={() => props.ctrl.submitChoice(choice)}
                disabled={!choicesEnabled}
              >
                {choice.text}
              </GamePlayButton>
            );
          })}
        </div>
      </main>

      <footer
        className={`
          relative w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5
          transition-all ease-out transform
          ${
            !result ? 'opacity-0 translate-y-full' : 'opacity-100 translate-y-0'
          }
        `}
      >
        <div
          className={`
            w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center
            ${result === 'correct' ? 'text-green-001' : 'text-red-006'}
          `}
        >
          {result === 'correct' ? (
            <span>
              <strong>Correct!</strong> Nice job!
            </span>
          ) : (
            <span>
              <strong>Incorrect!</strong> See correct answer above.
            </span>
          )}
        </div>
        <CommonButton
          variant={result === 'correct' ? 'correct' : 'incorrect'}
          onClick={() => props.ctrl.end()}
          disabled={status !== 'complete'}
        >
          Continue
        </CommonButton>
        {result === 'correct' && (
          <CorrectAnimationWithLayout onEnd={() => void 0} />
        )}
      </footer>
    </div>
  );
}
