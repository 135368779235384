import type { CSSProperties } from 'react';
import { useInView } from 'react-intersection-observer';

export function ScrollableContent(props: { children: React.ReactNode }) {
  const { ref, inView } = useInView();
  return (
    <div className='relative w-full h-full flex flex-col'>
      <div className='w-full min-h-0 flex-1 overflow-y-auto scrollbar'>
        {props.children}
        <div ref={ref} className='h-px' />
      </div>
      {!inView && (
        <div className='absolute bottom-0 left-0 right-0 flex justify-center'>
          <div
            className='px-2.5 py-1.5 bg-tertiary rounded-full text-black text-xs font-medium shadow-lg animate-fade-in-v2'
            style={
              {
                '--tw-fade-in-v2-duration': '400ms',
              } as CSSProperties
            }
          >
            Scroll for more content
          </div>
        </div>
      )}
    </div>
  );
}
