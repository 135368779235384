import {
  type ComponentProps,
  createContext,
  type ElementType,
  useContext,
} from 'react';

import { type ModelsAnimation } from '@lp-lib/api-service-client/public';
import { type BlockAnimations } from '@lp-lib/game';

import { uuidv4 } from '../../../utils/common';
import { getGlobalLVOLocalCtrl } from '../../VoiceOver/LocalLocalizedVoiceOvers';

export function defaultFadeInAnimation(triggerId = 'start'): ModelsAnimation {
  return {
    id: uuidv4(),
    name: 'fade-in',
    durationMs: 500,
    timingFunction: 'ease',
    triggerId,
  };
}

type AnimationRunner = () => Nullable<Animation>;
type AnimationRunnerFactory = (
  el: HTMLElement,
  animation: ModelsAnimation,
  initialize?: boolean
) => AnimationRunner;

const factories: Record<string, AnimationRunnerFactory> = {
  'fade-in': (el, animation, initialize) => {
    if (initialize) el.style.opacity = '0';
    const ref = new WeakRef(el);
    return () => {
      return ref.deref()?.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: animation.durationMs,
        easing: animation.timingFunction,
        fill: 'both',
      });
    };
  },
};

type AnimationRecord = {
  el: WeakRef<HTMLElement>;
  enter?: Nullable<{ runner: AnimationRunner; triggerId: string }>;
};

export class BlockAnimator<K extends string = string> {
  private keyMap: Map<K, AnimationRecord>;
  private elementMap: WeakMap<HTMLElement, K>;
  private triggerMap: Map<string, K[]>;
  private triggersRun: Set<string>;
  private _off: Nullable<() => void>;

  constructor(
    private animations: BlockAnimations<K> = {},
    private getLVOLocalCtrl = getGlobalLVOLocalCtrl
  ) {
    this.keyMap = new Map();
    this.elementMap = new WeakMap();
    this.triggerMap = new Map();
    this.triggersRun = new Set();
  }

  setAnimations(animations: BlockAnimations<K>, initialize = true) {
    this.animations = animations;

    // re-register all elements.
    const entries = Array.from(this.keyMap.entries());
    this.keyMap.clear();
    this.elementMap = new WeakMap();
    this.triggerMap.clear();

    for (const [key, record] of entries) {
      this.register(key, record.el.deref(), initialize);
    }
  }

  // turn on the block animator. we don't want to do this until the block actually starts because
  // the block ctrl will be preloaded...don't respond to markers until we're good and ready.
  on() {
    const lvoCtrl = this.getLVOLocalCtrl();
    if (lvoCtrl) {
      this.off = lvoCtrl.vm.on('marker-reached', (markerId) => {
        this.triggerAnimations(markerId);
      });
    }
  }

  off() {
    this._off?.();
  }

  ref(key: K, initialize = true) {
    return (el: Nullable<HTMLElement>) => this.register(key, el, initialize);
  }

  register(key: K, el: Nullable<HTMLElement>, initialize = true) {
    if (!el) return;
    // already registered to a different key.
    if (this.elementMap.has(el) && this.elementMap.get(el) !== key) return;

    // this key may be already be registered.
    const currentRecord = this.keyMap.get(key);
    if (currentRecord) {
      // if the element is the same, we're good.
      if (currentRecord.el.deref() === el) return;

      // if the element is different, we need to remove the old one.
      this.unregister(key);
    }

    // always push the record, though we may not have an animation for it.
    // this is primarily to support the editor view where we register the elements, and only
    // later in the editing process is an animation configured.
    const record: AnimationRecord = { el: new WeakRef(el) };
    this.keyMap.set(key, record);
    this.elementMap.set(el, key);

    const enterAnimation = this.animations[key]?.enter;
    if (!enterAnimation) return;

    const makeRunner = factories[enterAnimation.name];
    if (!makeRunner) return;

    const triggerId = enterAnimation.triggerId;
    const runner = makeRunner(el, enterAnimation, initialize);
    record.enter = { runner, triggerId };

    const runners = this.triggerMap.get(triggerId) ?? [];
    runners.push(key);
    this.triggerMap.set(triggerId, runners);
  }

  unregister(key: K) {
    const record = this.keyMap.get(key);
    if (!record) return;
    this.keyMap.delete(key);
    const el = record.el.deref();
    if (el) this.elementMap.delete(el);
    if (!record.enter) return;

    const triggers = this.triggerMap.get(record.enter.triggerId);
    if (!triggers) return;
    this.triggerMap.set(
      record.enter.triggerId,
      triggers.filter((k) => k !== key)
    );
  }

  async triggerAnimations(triggerId: string) {
    if (this.triggersRun.has(triggerId)) return;
    this.triggersRun.add(triggerId);
    const keys = this.triggerMap.get(triggerId) ?? [];
    const runners = [];
    for (const key of keys) {
      const record = this.keyMap.get(key);
      if (!record?.enter) continue;
      runners.push(record.enter.runner());
    }
    await Promise.allSettled(
      runners.map(async (r) => {
        await r?.finished;
        r?.commitStyles();
        r?.cancel();
      })
    );
  }

  reset(animations?: BlockAnimations<K>, initialize = true) {
    this.triggersRun.clear();
    if (animations) this.setAnimations(animations, initialize);
  }

  destroy() {
    this.off();
    this.keyMap.clear();
    this.triggerMap.clear();
    this.triggersRun.clear();
    this.elementMap = new WeakMap();
  }

  getPreview(key: K): Nullable<[HTMLElement, AnimationRunner]> {
    const record = this.keyMap.get(key);
    if (!record?.enter) return null;
    const el = record.el.deref();
    if (!el) return null;
    return [el, record.enter.runner];
  }
}

// utilities for blocks to provide an animator to their components.
const context = createContext<BlockAnimator | null>(null);

export function useBlockAnimator<K extends string>() {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('No provider: BlockAnimator');
  }
  return ctx as BlockAnimator<K>;
}

export function BlockAnimatorProvider<K extends string = string>(props: {
  children: React.ReactNode;
  value: BlockAnimator<K>;
}) {
  return (
    <context.Provider value={props.value}>{props.children}</context.Provider>
  );
}

export type BlockAnimationProps<
  K extends string,
  E extends ElementType = 'div'
> = {
  name: K;
  as?: E;
  noInit?: boolean;
  animator?: BlockAnimator<K>; // optional, will use context if not provided.
  children?: React.ReactNode;
} & Omit<ComponentProps<E>, 'ref' | 'name' | 'as' | 'children'>;

export function BlockAnimation<
  K extends string,
  E extends ElementType = 'div'
>({
  name,
  as,
  noInit,
  animator: providedAnimator,
  children,
  ...props
}: BlockAnimationProps<K, E>) {
  const contextAnimator = useContext(context);
  const animator = providedAnimator ?? contextAnimator;
  const Component = (as ?? 'div') as ElementType;

  return (
    <Component ref={animator?.ref(name, !noInit)} {...props}>
      {children}
    </Component>
  );
}
