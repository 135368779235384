import type {
  DtoBlock,
  DtoGame,
  DtoGamePack,
  DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

export class PlayCursor {
  constructor(
    readonly gamePack: DtoGamePack,
    private minigames: DtoGame[],
    private minigameBlocks: Map<DtoGame['id'], DtoBlock[]>,
    private _currentMinigameIndex: number,
    private _currentBlockIndex: number
  ) {}

  static FromGamePackResponse(
    bundle: DtoSingleGamePackResponse,
    gameIdToPlay: string
  ) {
    const { gamePack, games = [], blocks = [], progression } = bundle;

    // get the minigame index we want to play.
    const currentMinigameIndex = games.findIndex(
      (game) => game.id === gameIdToPlay
    );
    if (currentMinigameIndex === -1) {
      // this will've been verified in the loader...
      throw new Error('failed to find minigame for id');
    }

    // figure out where we should be in the minigame.
    const minigameProgression = progression?.progress?.[gameIdToPlay];
    if (!minigameProgression) {
      // this will've been verified in the loader...
      throw new Error('failed to find minigame progression');
    }

    // collect all the minigame blocks into a map...
    const minigameBlocks = blocks.reduce((map, block) => {
      if (!map.has(block.gameId)) {
        map.set(block.gameId, []);
      }
      map.get(block.gameId)?.push(block);
      return map;
    }, new Map<DtoGame['id'], DtoBlock[]>());

    const currentMinigameBlocks = minigameBlocks.get(gameIdToPlay) ?? [];
    const lastCompletedBlockIndex = currentMinigameBlocks.findIndex(
      (block) => block.id === minigameProgression.lastCompletedBlockId
    );

    return new PlayCursor(
      gamePack,
      games,
      minigameBlocks,
      currentMinigameIndex,
      lastCompletedBlockIndex + 1
    );
  }

  get currentMinigameIndex() {
    return this._currentMinigameIndex;
  }

  get currentBlockIndex() {
    return this._currentBlockIndex;
  }

  currentMinigame(): Nullable<DtoGame> {
    return this.minigames.at(this._currentMinigameIndex) ?? null;
  }

  currentMinigameBlocks(): Nullable<DtoBlock[]> {
    const minigameId = this.currentMinigame()?.id;
    if (!minigameId) return null;

    return this.minigameBlocks.get(minigameId) ?? null;
  }

  peekNextMinigame(): Nullable<DtoGame> {
    const idx = this._currentMinigameIndex + 1;
    return this.minigames.at(idx) ?? null;
  }

  nextMinigame(): Nullable<DtoGame> {
    this._currentMinigameIndex += 1;
    this._currentBlockIndex = 0;
    return this.currentMinigame();
  }

  withNextMinigame(): PlayCursor {
    const cursor = this.clone();
    cursor.nextMinigame();
    return cursor;
  }

  withMinigame(minigameId: string): PlayCursor {
    const cursor = this.clone();
    cursor._currentMinigameIndex = this.minigames.findIndex(
      (game) => game.id === minigameId
    );
    cursor._currentBlockIndex = 0;
    return cursor;
  }

  currentBlock(): Nullable<DtoBlock> {
    return this.currentMinigameBlocks()?.at(this._currentBlockIndex) ?? null;
  }

  peekNextBlock(): Nullable<DtoBlock> {
    const idx = this._currentBlockIndex + 1;
    return this.currentMinigameBlocks()?.at(idx) ?? null;
  }

  nextBlock(): Nullable<DtoBlock> {
    this._currentBlockIndex += 1;
    return this.currentBlock();
  }

  progressPct(): number {
    const numMinigameBlocks = this.currentMinigameBlocks()?.length ?? 0;
    if (numMinigameBlocks === 0) return 100;

    const numBlocksRemaining = numMinigameBlocks - this._currentBlockIndex;
    return (1 - numBlocksRemaining / numMinigameBlocks) * 100;
  }

  private clone() {
    return new PlayCursor(
      this.gamePack,
      this.minigames,
      this.minigameBlocks,
      this._currentMinigameIndex,
      this._currentBlockIndex
    );
  }
}
